import queryString from 'query-string'

const isDevKey = typeof window !== 'undefined' && queryString.parse(window.location.search).locale === 'dev_key'

export default defineI18nConfig(() => ({
  legacy: false,
  fallbackLocale: 'en-xx',
  defaultLocale: 'en-xx',
  cookie: 'locale',
  alwaysRedirect: true,
  messageResolver: isDevKey ? (obj: any, path: string) => path : undefined,
}))
